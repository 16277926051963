@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "~material-icons/iconfont/material-icons.css";
@import "~roboto-fontface/css/roboto/roboto-fontface.css";
@import "material-symbols";
@import "~prismjs/themes/prism.css";

/* You can add global styles to this file, and also import other style files */

/* html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */

.party {
    animation: party 5000ms infinite;
}

@keyframes party {
    0% {
        background: #00f;
    }
    10% {
        background: #8e44ad;
    }
    20% {
        background: #1abc9c;
    }
    30% {
        background: #d35400;
    }
    40% {
        background: #00f;
    }
    50% {
        background: #34495e;
    }
    60% {
        background: #00f;
    }
    70% {
        background: #2980b9;
    }
    80% {
        background: #f1c40f;
    }
    90% {
        background: #2980b9;
    }
    100% {
        background: #0ff;
    }
}

.adminSettingsModal > .mat-mdc-dialog-container {
    padding: 0px !important;
}

.pop-up-dialog-container .mat-mdc-dialog-container {
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0px 1px 3px #00000033;
    opacity: 1;
}

.setpoint-settings-dialog-container .mat-mdc-dialog-container {
    border-radius: 4px;
    box-shadow: 0px 1px 3px #00000033;
    opacity: 1;
    padding: 10px;
}
.mat-mdc-tab-body-content {
    overflow: hidden !important;
}

.setpoint-settings-dialog-container .mat-mdc-tab-body-content {
    overflow: hidden !important;
}

.custom-dialog-container .mat-mdc-dialog-container {
    border-radius: 4px;
    box-shadow: 0px 1px 3px #00000033;
    opacity: 1;
}

/* .custom-dialog-container .title {
    padding-right: 24px;
}

.custom-dialog-container .buttons {
    padding-right: 24px;
} */

.custom-dialog-container .mat-mdc-dialog-container {
    border-radius: 4px;
    box-shadow: 0px 1px 3px #00000033;
    opacity: 1;
}

@font-face {
    font-family: Fira Code;
    src: url(assets/font/FiraCode-Bold.otf) format("opentype");
}

.mat-mdc-tooltip {
    white-space: pre-line;
    max-width: 400px;
}

.opc-server-container .mdc-notched-outline__trailing {
    border-radius: 0 !important;
}

.opc-address-container .mdc-notched-outline__leading {
    border-radius: 0 !important;
}

.tag-type-container .mdc-notched-outline__trailing {
    border-radius: 0 !important;
}

.tags-container .mdc-notched-outline__leading {
    border-radius: 0 !important;
}

mat-card {
    padding: 16px;
}

.mat-mdc-card-header-text {
    margin: 0 16px;
}

.mat-mdc-card-title,
.mat-mdc-card-subtitle {
    margin-bottom: 12px !important;
}

.mat-mdc-raised-button[disabled][disabled] {
    color: rgba(0, 0, 0, 0.26);
    background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-button[disabled][disabled] {
    color: rgba(0, 0, 0, 0.26);
    background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-menu-content .mdc-list-item__primary-text {
    font-size: 14px !important;
    font-family: "Roboto", sans-serif !important;
    font-weight: 400 !important;
    letter-spacing: normal !important;
}

.mat-mdc-dialog-container {
    background-color: white;
    padding: 0px 24px 24px 24px;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
    font-family: "Roboto", sans-serif !important;
    box-shadow: unset !important;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
}

.mat-mdc-form-field {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 1.125 !important;
    font-family: "Roboto", sans-serif !important;
    letter-spacing: normal !important;
}

.mdc-floating-label {
    font-size: 12px !important;
    top: 63% !important;
}

.mat-mdc-form-field-infix {
    max-height: 48px;
    font-family: "Roboto", sans-serif !important;
}

.mat-mdc-form-field-flex {
    max-height: 48px;
    font-family: "Roboto", sans-serif !important;
}

.mat-mdc-paginator .mat-mdc-select-value {
    font-size: 11px !important;
    font-family: "Roboto", sans-serif !important;
    letter-spacing: normal !important;
}

.mat-mdc-option {
    font-size: 14px !important;
    font-family: "Roboto", sans-serif !important;
    letter-spacing: normal !important;
}

.mdc-text-field__input::-webkit-calendar-picker-indicator {
    display: block !important;
}

.mdc-text-field__input {
    margin-top: 5px;
}

.mdc-button {
    min-width: 38px !important;
}

.subCard .mdc-floating-label {
    top: 30px !important;
}

.subCard .mat-mdc-form-field-flex {
    max-height: unset !important;
}

.subCard .mat-mdc-form-field-infix {
    max-height: unset !important;
}

.tooltip-icon {
    font-size: 18px !important;
}

.green-icon {
    color: #00cb92;
    float: left;
    margin-top: 4px;
}

.red-icon {
    color: #ff0000;
    float: left;
    margin-top: 4px;
}

.black-icon {
    color: #000000;
    float: left;
    margin-top: 4px;
}

.grey-icon {
    color: #948f8f;
    float: left;
    margin-top: 4px;
}

.divider {
    border: 1px solid rgba(128, 128, 128, 0.5);
    display: block;
}

.text-block {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    width: auto;
}
.red-text {
    color: #ff0000;
}
