@use "@angular/material" as mat;
$black-87-opacity: rgba(0, 0, 0, 0.87);

// You can define colors here (using http://mcg.mbitson.com)
$mat-primary: (
    50: #e5eff4,
    100: #bdd7e3,
    200: #92bdd0,
    300: #66a3bd,
    400: #458fae,
    500: #247ba0,
    600: #207398,
    700: #1b688e,
    800: #165e84,
    900: #0d4b73,
    A100: #a6d8ff,
    A200: #73c2ff,
    A400: #40acff,
    A700: #26a1ff,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$mat-accent: (
    50: #fffaef,
    100: #fff4d8,
    200: #ffecbe,
    300: #ffe4a4,
    400: #ffdf91,
    500: #ffd97d,
    600: #ffd575,
    700: #ffcf6a,
    800: #ffca60,
    900: #ffc04d,
    A100: #ffffff,
    A200: #ffffff,
    A400: #fffbf3,
    A700: #fff1da,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #000000,
        700: #000000,
        800: #000000,
        900: #000000,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

// mandatory stuff for theming
$egret-primary: mat.define-palette($mat-primary);
$egret-accent: mat.define-palette($mat-accent);

// include the custom theme components into a theme object
$egret-theme: mat.define-light-theme($egret-primary, $egret-accent);

$primary-dark: darken(map-get($mat-primary, 500), 8%);

// include the custom theme object into the angular material theme
.egret-blue {
    @include mat.all-component-themes($egret-theme);

    .mat-bg-primary,
    .topbar,
    .chats-wrap .conversations-hold .single-conversation.me .conversation-msg,
    .ngx-datatable .datatable-footer .datatable-pager .pager .pages.active a,
    .fileupload-drop-zone.dz-file-over,
    .toolbar-avatar.online > .status-dot,
    .cal-open-day-events,
    div.hopscotch-bubble {
        background: map-get($mat-primary, 500) !important;
        color: #ffffff !important;
    }
    .mat-color-primary,
    .list-item-active,
    .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
    .sidenav-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
    .sidenav-hold .sidenav li.open > a > span {
        color: map-get($mat-primary, 500) !important;
    }
    .topnav ul.menu > li > div.open > a,
    .topnav ul.menu > li > div.open > div,
    .sidebar-panel .sidebar-list-item.open > .mdc-list-item__content > .sub-menu,
    .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
    .list-item-active {
        border-color: map-get($mat-primary, 500) !important;
    }
    .sidebar-panel {
        background: url("../../../../assets/images/background/sidebar-bg.jpg") no-repeat;
    }
    .sidebar-compact ul.submenu,
    .breadcrumb-bar,
    .default-bg {
        background: #ffffff !important;
    }
    /* Hopscotch Tour */
    div.hopscotch-bubble {
        border-color: $primary-dark;
    }
    /* up arrow  */
    div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow-border {
        border-bottom: 17px solid $primary-dark;
    }
    div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow {
        border-bottom: 17px solid map-get($mat-primary, 500);
        top: -16px;
    }
    /* right arrow */
    div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow-border {
        border-left: 17px solid $primary-dark;
    }
    div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow {
        border-left: 17px solid map-get($mat-primary, 500);
        left: -1px;
    }
    /* bottom arrow */
    div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow-border {
        border-top: 17px solid $primary-dark;
    }
    div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow {
        border-top: 17px solid map-get($mat-primary, 500);
        top: -18px;
    }
    /* Left Arrow */
    div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow-border {
        border-right: 17px solid $primary-dark;
    }
    div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow {
        left: 1px;
        border-right: 17px solid map-get($mat-primary, 500);
    }

    .mat-bg-accent,
    div.hopscotch-bubble .hopscotch-bubble-number,
    div.hopscotch-bubble .hopscotch-nav-button.next:hover,
    div.hopscotch-bubble .hopscotch-nav-button.next {
        background-color: map-get($mat-accent, 500);
        color: black;
    }
    .mat-bg-warn {
        background-color: #f44336;
        color: white;
    }
    .mat-color-accent {
        color: map-get($mat-accent, 500);
    }
    .mat-color-warn {
        color: #f44336;
    }
    .mat-color-default {
        color: rgba(0, 0, 0, 0.87);
    }
}
