.mat-toolbar {
    box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
}
.header-topnav {
    margin: 0;
    padding: 0;
    background-color: #ffffff;
    position: relative;
    z-index: 999;
    .container {
        padding: 0;
    }
    .topbar-branding {
        float: left;
        height: 48px;
        padding: 8px;
        margin: 0 10px;
        img {
            height: 100%;
            width: auto;
        }
    }
    .topnav {
        // margin-left: -19px;
        display: flex;
        align-items: center;
    }
    .header-topnav-right {
        float: right;
        height: 48px;
        display: flex;
        align-items: center;
        padding-right: 0.67rem;
    }
}

@media (max-width: 959px) {
    .header-topnav-right {
        position: absolute;
        right: 6px;
        top: 0;
    }
}

[dir="rtl"] {
    .header-topnav {
        .topnav {
            flex-direction: row-reverse;
        }
    }
}
