.home-section {
    padding: 80px 0;
}
.scrollable {
    position: relative;
    width: 100%;
    height: 100vh;
}
.section-intro {
    padding-top: 40px;
    background: #ffffff;
    h1 {
        font-size: 32px;
        margin: 0 0 1rem;
        font-weight: 300;
    }
    p {
        font-size: 16px;
        max-width: 450px;
        margin: 0 auto 1.4rem;
    }
    img {
        display: block;
        margin: 0 auto 1.2rem;
    }
}
.section-demos {
    position: relative;
    background: #1e5799;
    .demo-box {
        h3 {
            margin: 0 0 1.4rem;
            font-size: 24px;
            font-weight: 700;
            color: #ffffff;
            text-shadow: 0 2px 2px rgba(0, 0, 0, 0.35);
        }
        img {
            width: 100%;
            height: auto;
            float: left;
        }
        .screenshot {
            overflow: hidden;
            border-radius: 8px;
            margin-bottom: 2rem;
            min-height: 180px;
            cursor: pointer;
        }
    }
}
