.topbar {
    &.mat-toolbar {
        position: relative;
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    }
    .mat-mdc-select-value {
        font-weight: 500;
        font-size: 20px !important;
    }
}

.toggle-collapsed {
    transform: rotate(0deg);
    -webkit-transition: transform 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    transition: transform 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
.sidebar-compact .toggle-collapsed {
    transform: rotate(180deg);
}
.search-bar {
    .top-search-form {
        position: relative;
        background: #fff;
        border-radius: 2px;
        margin-right: 1rem;
        display: block;
        max-width: 800px;
        margin-left: 20px;
        .material-icons {
            position: absolute;
            top: 50%;
            left: 10px;
            margin-top: -12px;
            color: rgba(0, 0, 0, 0.87);
        }
        input {
            font-size: 1rem;
            padding: 0.95rem 0.75rem;
            z-index: 2;
            cursor: text;
            text-indent: 30px;
            border: none;
            background: transparent;
            width: 100%;
            outline: 0;
        }
    }
}
