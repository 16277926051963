/*---- Session (Sign in, sign up, forgot, lockscreen) -----*/
.page-wrap {
    display: flex;
    align-items: center;
    padding: 40px 1rem;
    height: 100%;
}
.session-progress {
    position: relative;
    bottom: -5px;
    z-index: 9999;
}
.session-form-hold {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}
.session-form-hold > .mat-mdc-card {
    margin: 0;
}
.session-lockscreen {
    max-width: 320px;
}
.lockscreen-user {
    text-align: center;
    margin-bottom: 1rem;
    display: flow-root;
    overflow: hidden;
}
.lockscreen-face {
    max-width: 72px;
    max-height: 72px;
    border-radius: 50%;
    margin-bottom: 0.5rem;
}
