/*
 *
 *   EGRET - Angular Material Design Admin Template
 *
 *
*/

// Third pirty style files
// Sidebar Scroll
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Page loader Topbar
@import "../../vendor/pace/pace-theme-min.css";

// Main Variables File
@import "scss/variables";

// Open _themes.scss and remove unnecessary theme files
@import "scss/themes";

// Open _main.scss and keep files which are required for your current layout.
@import "scss/main";

// Open _views.scss and remove unnecessary view related files
@import "scss/views";
