/*---- Calendar -----*/
.card-title-text.calendar-title {
    padding: 0.5rem 1.5rem !important;
}
.cal-top-col {
    width: 33.3333%;
    float: left;
}
.cal-event-action .material-icons {
    vertical-align: sub;
    margin: 6px;
}
.cal-open-day-events {
    box-shadow: none !important;
}

@media (max-width: 767px) {
    .cal-top-col {
        width: 100%;
    }
}
