.app-admin-container {
    max-width: 100%;
    height: 100vh;
    .rightside-content-hold {
        background-color: #eeeeee;
        padding: 0.333333rem;
        overflow-x: hidden;
        position: relative;
        min-height: 450px;
    }
}

.sidebar-panel {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
    z-index: 10001;
    width: $sidebar-width;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    -webkit-transition: all $sidebarTrnTime $transitionTiming;
    transition: all $sidebarTrnTime $transitionTiming;
    background: transparent linear-gradient(180deg, #428fda 0%, #1976d2 100%) 0% 0% no-repeat padding-box;
    .navigation-hold {
        position: absolute;
        height: 100%;
        width: 100%;
        padding-top: 64px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        left: 0;
        -webkit-transition: all $sidebarTrnTime $transitionTiming;
        transition: all $sidebarTrnTime $transitionTiming;
    }
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
}

.main-content-wrap {
    position: relative;
    height: 100vh;
    width: 100%;
    transition: width $sidebarTrnTime $transitionTiming;
    background-color: #eeeeee;
    &.ps > .ps__rail-y {
        z-index: 9999;
    }
}

.sidebar-backdrop {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    display: block;
    z-index: 10000;
    visibility: hidden;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all $sidebarTrnTime $transitionTiming;
    &.visible {
        visibility: visible;
        opacity: 1;
    }
}

// --------
.app-admin-container.sidebar-full:not(.navigation-top) {
    .main-content-wrap {
        width: calc(100% - #{$sidebar-width});
    }
    .sidebar-panel {
        width: $sidebar-width;
        overflow: hidden;
    }
}

.app-admin-container.sidebar-compact {
    .main-content-wrap {
        width: calc(100% - #{$compact-sidebar-width});
    }
    .sidebar-panel {
        width: $compact-sidebar-width;
        .branding {
            width: $compact-sidebar-width;

            .app-logo {
                margin: auto;
            }
        }
        .navigation-hold {
            overflow: visible !important;
        }
    }
}

.app-admin-container.sidebar-compact-big {
    .main-content-wrap {
        width: calc(100% - #{$compact-big-sidebar-width});
    }
    .sidebar-panel {
        width: $compact-big-sidebar-width;
        .branding {
            width: $compact-big-sidebar-width;
            .app-logo {
                margin: auto;
            }
        }
        .navigation-hold {
            overflow: visible !important;
        }
    }
}

.app-admin-container.sidebar-closed {
    .sidebar-panel {
        overflow: hidden;
        left: -$sidebar-width;
        .branding {
            left: -$sidebar-width;
            width: 48px;
            .app-logo-text {
                // display: none;
            }
        }
    }
    .main-content-wrap {
        width: 100%;
    }
}

.fixed-topbar {
    .rightside-content-hold {
        overflow: auto;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 64px;
    }
}

.layout-intransition {
    .branding {
        display: none !important;
    }

    .app-user-controls,
    .app-user-name {
        // visibility: hidden;
        opacity: 0 !important;
    }
}

.sidebar-compact,
.sidebar-compact-big {
    .app-user,
    .app-logo-text {
        display: none;
    }
}

[dir="rtl"] {
    &.app-admin-container.sidebar-closed {
        .sidebar-panel {
            right: -$sidebar-width;
        }
        .branding {
            left: auto !important;
            right: -$sidebar-width;
        }
    }
    .main-content-wrap {
        float: left;
    }
    .sidebar-panel {
        right: 0;
        left: auto !important;
    }
}

.app-admin-container.sidebar-full.navigation-top {
    .sidebar-panel {
        left: 0;
    }
}
.app-admin-container.navigation-top {
    .main-content-wrap {
        float: none;
        height: calc(100vh - 48px);
    }
    .rightside-content-hold {
        max-width: 1200px;
        margin: 0 auto;
    }
    .sidebar-panel {
        overflow: hidden;
        left: -$sidebar-width;
    }
}

.main-content-container {
    padding: 0 175px;
    padding-top: 30px;
}

@media screen and (max-width: 1920px) {
    .main-content-container {
        padding-top: 30px;
        padding-bottom: 0;
        padding-left: calc(4.5% + 50px);
        padding-right: 4.5%;
    }
}

@media (max-width: 959px) {
    .app-admin-container.sidebar-full {
        .main-content-wrap {
            width: 100% !important;
        }
    }
}
